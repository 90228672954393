var firebaseConfig = {
  apiKey: "AIzaSyBNIXv7rYW48wCmyIIZK_tXAXdFGW9ylOg",
  authDomain: "crosswordstogether.com",
  databaseURL: "https://crosswords-1e9f0.firebaseio.com",
  projectId: "crosswords-1e9f0",
  storageBucket: "crosswords-1e9f0.appspot.com",
  messagingSenderId: "847675267519",
  appId: "1:847675267519:web:4a13dc8a347d10796ba90a",
  measurementId: "G-X38VZ37EMC"
};

export default firebaseConfig;
